@font-face {
	font-family: 'Arial';
	src: url('../fonts/Arial.eot');
	src: url('../fonts/Arial.woff') format('woff'),
		url('../fonts/Arial.ttf') format('truetype');
}

@font-face {
	font-family: 'Georgia';
	src: url('../fonts/Georgia.eot');
	src: url('../fonts/Georgia.woff') format('woff'),
		url('../fonts/Georgia.ttf') format('truetype');
}

@font-face {
	font-family: 'Verdana';
	src: url('../fonts/Verdana.eot');
	src: url('../fonts/Verdana.woff') format('woff'),
		url('../fonts/Verdana.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto.eot');
	src: url('../fonts/Roboto.woff') format('woff'),
		url('../fonts/Roboto.ttf') format('truetype');
}

@font-face {
	font-family: 'RobotoMono-Regular';
	src: url('../fonts/robotomono-regular.eot');
	src: url('../fonts/robotomono-regular.woff') format('woff'),
		url('../fonts/robotomono-regular.ttf') format('truetype');
}

@font-face {
	font-family: 'RobotoMono-Bold';
	src: url('../fonts/robotomono-bold.eot');
	src: url('../fonts/robotomono-bold.woff') format('woff'),
		url('../fonts/robotomono-bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Calibri';
	src: url('../fonts/calibri-regular.eot');
	src: url('../fonts/calibri-regular.woff') format('woff'),
		url('../fonts/calibri-regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Calibri-Bold';
	src: url('../fonts/calibri-bold.eot');
	src: url('../fonts/calibri-bold.woff') format('woff'),
		url('../fonts/calibri-bold.ttf') format('truetype');
}
