/* Variables */
/* Animation */
@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes fade {
  from {
    opacity: 0; } }

.rotating {
  animation: rotation 2s linear infinite; }

/* Main */
body {
  font-family: 'Roboto', sans-serif; }

/* Debugging */
pre {
  text-align: left;
  color: tomato;
  background: blanchedalmond;
  border: 3px dashed tomato; }

/* Navbar */
.navbar-header img {
  height: 50px; }

.navbar-fixed-top {
  border-bottom: 4px solid #00b3c4; }

/* Header */
.page-header .subitem {
  color: #cccccc; }

.page-header .btn-toolbar {
  float: right; }

.page-header:after {
  content: '';
  display: block;
  clear: both; }

/* Background */
html,
body,
#root,
.App {
  width: 100%;
  height: 100%; }

#root {
  padding-top: 50px; }

.splash {
  background-image: url("images/background1.svg"), url("images/background2.svg");
  background-size: 540px;
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  height: 100%; }

/* Table */
.item-hidden {
  color: #999999;
  background-color: #cccccc; }
  .item-hidden:hover {
    background-color: #cccccc !important; }
  .item-hidden .preview {
    opacity: 0.5; }

/* Upload */
#filesButton {
  margin-bottom: 20px; }

#files, #file {
  display: none; }

/* Alignment */
.vertical-center {
  height: 100%;
  display: flex; }
  .vertical-center > div {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 25%; }
  .vertical-center div {
    display: block; }

.full-width {
  width: 100%; }

/* Forms */
.modal-body .col-sm-1,
.modal-body .col-sm-2,
.modal-body .col-sm-3,
.modal-body .col-sm-4,
.modal-body .col-sm-5,
.modal-body .col-sm-6,
.modal-body .col-sm-12 {
  padding: 0; }

.modal-body .input-group-addon {
  padding: 6px; }

.modal-body .dropdown-toggle {
  margin-right: 6px; }

.modal-body .btn-group {
  margin-bottom: 6px; }

.modal-body .form-group {
  margin: 0 15px 6px 0; }
  .modal-body .form-group .btn-group {
    margin-bottom: 0; }

.modal-body .col-sm-12 .form-group {
  margin-right: 0; }

.modal-body .form-control {
  padding: 6px; }

.text-light {
  color: #ccc; }

.nav-tabs {
  margin-bottom: 30px; }

.media-preview {
  padding: 30px 0;
  text-align: center; }
.media-preview .preview {
  margin-top: 15px; }

/* Source: https://medium.com/wdstack/bootstrap-equal-height-columns-d07bc934eb27 */
.row.display-flex {
  display: flex;
  flex-wrap: wrap; }
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column; }

.thumbnail {
  cursor: pointer; }

.thumbnail .caption {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px 4px 0 0; }

.thumbnail.selected {
  background-color: #337ab7; }

.thumbnail.selected .caption {
  color: #fff; }

.modal-wide .modal-lg {
  width: 100%; }

.monospace {
  font-family: monospace; }

/* Preview */
.preview {
  image-rendering: pixelated; }

.preview canvas {
  display: block; }

/* Preview playertype addco - colorize bitmaps simpleimage */
.preview .addco {
  background-color: yellow; }

.preview .addco canvas {
  mix-blend-mode: multiply;
  filter: grayscale(1); }

/* Preview playertype qlk - always rectangle */
.thumbnail .qlkpreview {
  margin: 0 auto; }

.qlkpreview {
  image-rendering: pixelated;
  max-width: 200px;
  height: 100%; }

.qlkpreview div {
  clip-path: polygon(0 34%, 34% 34%, 34% 0, 66% 0, 66% 34%, 100% 34%, 100% 66%, 66% 66%, 66% 100%, 34% 100%, 34% 66%, 0 66%);
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 100%;
  background-size: 100%; }

/* Form inline edit time */
.inlineedittime {
  width: 6em;
  margin: 0 4px 4px;
  padding: 0 4px 4px;
  border-bottom: 1px solid #eee;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  text-align: center; }

/* Project playertype qlk */
.qlkdash {
  padding-top: 30px; }
.qlkdash .status-preview,
.qlkdash .status-qlkmode {
  padding: 15px 0; }
.qlkdash .nav-tabs {
  margin: 0;
  padding: 9px 0 0 0; }
.qlkdash .page-header {
  border: 0;
  padding: 9px 0 0 0; }
.qlkdash .page-header,
.qlkdash .page-header h1 {
  margin: 0; }